.cardContainer {
  display: flex;
  justify-content: center;
  align-content: center;
  flex-wrap: wrap;
  background-color: transparent;
  border: none;
  box-shadow: none;
  width: 85%;
  height: 55vh;
  padding-block: 3rem;
  padding-inline: 4.5rem;
}

.wrapper {
  background: url('/entrega.webp');
  width: 100%;
  height: 100%;
  background-size: cover;
  display: flex;
  justify-content: center;
  align-items: center;
}

.card,
.cardChip {
  overflow: hidden;
  position: relative;
}

.card,
.cardChipTexture,
.cardTexture {
  -webkit-animation-duration: 3s;
          animation-duration: 3s;
  -webkit-animation-timing-function: ease-in-out;
          animation-timing-function: ease-in-out;
  -webkit-animation-iteration-count: infinite;
          animation-iteration-count: infinite;
}

.card {
  -webkit-animation-name: rotate_500;
          animation-name: rotate_500;
  background-color: #06a1ac;
  background-image: radial-gradient(circle at 100% 0%,hsla(0,0%,100%,0.08) 29.5%,hsla(0,0%,100%,0) 30%),
        radial-gradient(circle at 100% 0%,hsla(0,0%,100%,0.08) 39.5%,hsla(0,0%,100%,0) 40%),
        radial-gradient(circle at 100% 0%,hsla(0,0%,100%,0.08) 49.5%,hsla(0,0%,100%,0) 50%);
  border-radius: 0.5em;
  -webkit-box-shadow: 0 0 0 hsl(0,0%,80%),
        0 0 0 hsl(0,0%,100%),
        -0.2rem 0 0.75rem 0 hsla(0,0%,0%,0.3);
          box-shadow: 0 0 0 hsl(0,0%,80%),
        0 0 0 hsl(0,0%,100%),
        -0.2rem 0 0.75rem 0 hsla(0,0%,0%,0.3);
  color: hsl(0,0%,100%);
  width: 16.3em;
  height: 9.3em;
  -webkit-transform: translate3d(0,0,0);
          transform: translate3d(0,0,0);
}

.cardInfo,
.cardChipTexture,
.cardTexture {
  position: absolute;
}

.cardChipTexture,
.cardTexture {
  -webkit-animation-name: texture;
          animation-name: texture;
  top: 0;
  left: 0;
  width: 200%;
  height: 100%;
}

.cardInfo {
  font: 0.75em/1 "DM Sans", sans-serif;
  display: -webkit-box;
  display: -ms-flexbox;
  display: flex;
  -webkit-box-align: center;
      -ms-flex-align: center;
          align-items: center;
  -ms-flex-wrap: wrap;
      flex-wrap: wrap;
  padding: 0.75rem;
  inset: 0;
}

.cardLogo,
.cardNumber {
  width: 100%;
}

.cardLogo {
  font-weight: bold;
  font-style: italic;
}

.cardChip {
  background-image: -webkit-gradient(linear,left top, left bottom,from(hsl(0,0%,70%)),to(hsl(0,0%,80%)));
  background-image: linear-gradient(hsl(0,0%,70%),hsl(0,0%,80%));
  border-radius: 0.2rem;
  -webkit-box-shadow: 0 0 0 0.05rem hsla(0,0%,0%,0.5) inset;
          box-shadow: 0 0 0 0.05rem hsla(0,0%,0%,0.5) inset;
  width: 1.25rem;
  height: 1.25rem;
  -webkit-transform: translate3d(0,0,0);
          transform: translate3d(0,0,0);
}

.cardChipLines {
  width: 100%;
  height: auto;
}

.cardChipTexture {
  background-image: linear-gradient(-80deg,hsla(0,0%,100%,0),hsla(0,0%,100%,0.6) 48% 52%,hsla(0,0%,100%,0));
}

.cardDigitGroup,
.cardName {
  background: -webkit-gradient(linear,left top, left bottom,from(hsl(0,0%,100%)),color-stop(15%, hsl(0,0%,85%)),color-stop(70%, hsl(0,0%,70%)));
  background: linear-gradient(hsl(0,0%,100%),hsl(0,0%,85%) 15% 55%,hsl(0,0%,70%) 70%);
  background-clip: text;
  -webkit-background-clip: text;
}

.cardNumber {
  font-size: 1rem;
  display: -webkit-box;
  display: -ms-flexbox;
  display: flex;
  -webkit-box-pack: justify;
      -ms-flex-pack: justify;
          justify-content: space-between;
}

.cardName {
  text-transform: uppercase;
}

.cardExpDate {
  margin-bottom: 0.25rem;
  width: 50%;
}

.cardName {
  font-size: 0.8rem;
}

.cardName {
  overflow: hidden;
  white-space: nowrap;
  width: 7rem;
}

.cardTexture {
  -webkit-animation-name: texture;
          animation-name: texture;
  background-image: linear-gradient(-80deg,hsla(0,0%,100%,0.3) 25%,hsla(0,0%,100%,0) 45%);
}

/* Animation */
@-webkit-keyframes rotate_500 {
  from,
    to {
    -webkit-animation-timing-function: ease-in;
            animation-timing-function: ease-in;
    -webkit-box-shadow: 0 0 0 hsl(0,0%,80%),
            0.1rem 0 0 hsl(0,0%,100%),
            -0.2rem 0 0.75rem 0 hsla(0,0%,0%,0.3);
            box-shadow: 0 0 0 hsl(0,0%,80%),
            0.1rem 0 0 hsl(0,0%,100%),
            -0.2rem 0 0.75rem 0 hsla(0,0%,0%,0.3);
    -webkit-transform: rotateY(-10deg);
            transform: rotateY(-10deg);
  }

  25%,
    75% {
    -webkit-animation-timing-function: ease-out;
            animation-timing-function: ease-out;
    -webkit-box-shadow: 0 0 0 hsl(0,0%,80%),
            0 0 0 hsl(0,0%,100%),
            -0.25rem -0.05rem 1rem 0.15rem hsla(0,0%,0%,0.3);
            box-shadow: 0 0 0 hsl(0,0%,80%),
            0 0 0 hsl(0,0%,100%),
            -0.25rem -0.05rem 1rem 0.15rem hsla(0,0%,0%,0.3);
    -webkit-transform: rotateY(0deg);
            transform: rotateY(0deg);
  }

  50% {
    -webkit-animation-timing-function: ease-in;
            animation-timing-function: ease-in;
    -webkit-box-shadow: -0.1rem 0 0 hsl(0,0%,80%),
            0 0 0 hsl(0,0%,100%),
            -0.3rem -0.1rem 1.5rem 0.3rem hsla(0,0%,0%,0.3);
            box-shadow: -0.1rem 0 0 hsl(0,0%,80%),
            0 0 0 hsl(0,0%,100%),
            -0.3rem -0.1rem 1.5rem 0.3rem hsla(0,0%,0%,0.3);
    -webkit-transform: rotateY(10deg);
            transform: rotateY(10deg);
  }
}
@keyframes rotate_500 {
  from,
    to {
    -webkit-animation-timing-function: ease-in;
            animation-timing-function: ease-in;
    -webkit-box-shadow: 0 0 0 hsl(0,0%,80%),
            0.1rem 0 0 hsl(0,0%,100%),
            -0.2rem 0 0.75rem 0 hsla(0,0%,0%,0.3);
            box-shadow: 0 0 0 hsl(0,0%,80%),
            0.1rem 0 0 hsl(0,0%,100%),
            -0.2rem 0 0.75rem 0 hsla(0,0%,0%,0.3);
    -webkit-transform: rotateY(-10deg);
            transform: rotateY(-10deg);
  }

  25%,
    75% {
    -webkit-animation-timing-function: ease-out;
            animation-timing-function: ease-out;
    -webkit-box-shadow: 0 0 0 hsl(0,0%,80%),
            0 0 0 hsl(0,0%,100%),
            -0.25rem -0.05rem 1rem 0.15rem hsla(0,0%,0%,0.3);
            box-shadow: 0 0 0 hsl(0,0%,80%),
            0 0 0 hsl(0,0%,100%),
            -0.25rem -0.05rem 1rem 0.15rem hsla(0,0%,0%,0.3);
    -webkit-transform: rotateY(0deg);
            transform: rotateY(0deg);
  }

  50% {
    -webkit-animation-timing-function: ease-in;
            animation-timing-function: ease-in;
    -webkit-box-shadow: -0.1rem 0 0 hsl(0,0%,80%),
            0 0 0 hsl(0,0%,100%),
            -0.3rem -0.1rem 1.5rem 0.3rem hsla(0,0%,0%,0.3);
            box-shadow: -0.1rem 0 0 hsl(0,0%,80%),
            0 0 0 hsl(0,0%,100%),
            -0.3rem -0.1rem 1.5rem 0.3rem hsla(0,0%,0%,0.3);
    -webkit-transform: rotateY(10deg);
            transform: rotateY(10deg);
  }
}

@-webkit-keyframes texture {
  from,
    to {
    -webkit-transform: translate3d(0,0,0);
            transform: translate3d(0,0,0);
  }

  50% {
    -webkit-transform: translate3d(-50%,0,0);
            transform: translate3d(-50%,0,0);
  }
}

@keyframes texture {
  from,
    to {
    -webkit-transform: translate3d(0,0,0);
            transform: translate3d(0,0,0);
  }

  50% {
    -webkit-transform: translate3d(-50%,0,0);
            transform: translate3d(-50%,0,0);
  }
}

.title {
  width: 100%;
  transition: transform 0.5s;
  /* font-family: 'creveland-midleton'; */
  color: var(--color-primary-4);
  font-size: 5vw;
  -webkit-text-stroke-width: 1px;
  -webkit-text-stroke-color: var(--color-secondary-2-0);
  -webkit-text-fill-color: var(--color-primary-4);
  letter-spacing: 2px;
  text-shadow: 3px 4px 5px var(--color-primary-4), 4px -2px 5px var(--color-primary-4);
  font-weight: 400;
}

@media (min-width: 600px) and (max-width: 767) {
  .cardContainer {
    width: 90%;
  }

  .title {
    font-size: 4vw;
    font-weight: 200;
    -webkit-text-stroke-color: var(--color-secondary-2-1);
    -webkit-text-fill-color: var(--color-secondary-2-4);
  }
}

@media (min-width: 768px) {
  .cardContainer {
    width: 50% !important;
    height: 55vh;
    padding-block: 3rem;
    padding-inline: 4.5rem;
  }

  .title {
    font-size: 2.5vw;
    font-weight: 200;
    -webkit-text-stroke-color: var(--color-secondary-2-1);
    -webkit-text-fill-color: var(--color-secondary-2-4);
  }
}

@media (min-width: 1500px) {
  .title {
    font-size: 2.75vw;
    -webkit-text-stroke-color: var(--color-secondary-2-1);
    -webkit-text-fill-color: var(--color-secondary-2-4);
  }
}