.cardContainer {
  display: flex;
  justify-content: center;
  align-content: center;
  flex-wrap: wrap;
  background-color: transparent;
  border: none;
  box-shadow: none;
  /* width: 90%; */
  height: 55vh;
}

.giftCard {
  width: 80%;
  height: 80%;
  position: relative;
  display: flex;
  justify-content: center;
  align-items: flex-end;
  padding: 0 36px;
  perspective: 2500px;
  margin: 0 50px;
}

.cover-image {
  width: 100%;
  height: 100%;
  object-fit: cover;
}

.wrapper {
  transition: all 0.5s;
  position: absolute;
  width: 100%;
  height: 100%;
  z-index: -1;
}

.giftCard:hover .wrapper {
  transform: perspective(900px) translateY(-5%) rotateX(25deg) translateZ(0);
  box-shadow: 2px 35px 32px -8px rgba(0, 0, 0, 0.75);
  -webkit-box-shadow: 2px 35px 32px -8px rgba(0, 0, 0, 0.75);
  -moz-box-shadow: 2px 35px 32px -8px rgba(0, 0, 0, 0.75);
}

.wrapper::before,
.wrapper::after {
  content: "";
  opacity: 0;
  width: 100%;
  height: 80px;
  transition: all 0.5s;
  position: absolute;
  left: 0;
}
.wrapper::before {
  top: 0;
  height: 100%;
  background-image: linear-gradient(
    to top,
    transparent 46%,
    rgba(12, 13, 19, 0.5) 68%,
    rgba(12, 13, 19) 97%
  );
}
.wrapper::after {
  bottom: 0;
  opacity: 1;
  background-image: linear-gradient(
    to bottom,
    transparent 46%,
    rgba(12, 13, 19, 0.5) 68%,
    rgba(12, 13, 19) 97%
  );
}

.giftCard:hover .wrapper::before,
.wrapper::after {
  opacity: 1;
}

.giftCard:hover .wrapper::after {
  height: 120px;
}
.title {
  width: 100%;
  transition: transform 0.5s;
  /* font-family: 'creveland-midleton'; */
  color: var(--color-primary-2);
  font-size: 6vw;
}
.giftCard:hover .title {
  transform: translate3d(0%, -50px, 100px);
}

.gift {
  height: 80%;
  width: 100%;
  opacity: 0;
  transition: all 0.5s;
  position: absolute;
  z-index: -1;
}

.giftCard:hover .gift {
  opacity: 1;
  transform: translate3d(0%, -30%, 50px);
}

@media (min-width: 768px) {
  .cardContainer {
    width: 50% !important;
  }

  .title {
    font-size: 4vw;
  }
}

@media (min-width: 1500px) {
  .title {
    font-size: 3vw;
  }
}


